import { SerializedStyles, css } from '@emotion/react'
import Brand from '@common/components/brand'
import { Dashboard } from '@layouts/core/dashboard'
import { IHeadCommonProps } from '@common/components/head/types'
import React from 'react'
import styled from '@emotion/styled'
import { mediaQuery } from '@common/utils/breakpoints'

const AuthLayoutWrapper = styled.div`
  position: relative;

  & .sidebar-dashboard {
    grid-template-columns: 2000px 1fr !important;
    background-color: #f4f4f7;
  }
`

interface AuthLayoutProps extends Partial<IHeadCommonProps> {
  backgroundIcon?: 'welcome' | 'builder' | 'none' | 'welcome-full'
  pageWidth?: string
  dashboardMainCss?: SerializedStyles
}
const AuthLayout: React.FC<React.PropsWithChildren<AuthLayoutProps>> = (
  props,
) => {
  const {
    children,
    backgroundIcon = 'none',
    pageWidth = '100%',
    dashboardMainCss,
    metaTitleFormatMessage,
  } = props

  return (
    <>
      <AuthLayoutWrapper>
        <Dashboard metaTitleFormatMessage={metaTitleFormatMessage}>
          <Dashboard.Sidebar
            css={css`
              ${mediaQuery('xLarge2')} {
                width: 90%;
              }
            `}
          >
            <Brand.Logo
              imgSize="xl"
              imgCss={css`
                position: relative;
                width: 140px;
                height: 54px;
                margin-top: 30px;

                ${mediaQuery('xLarge')} {
                  width: 100px;
                }

                ${mediaQuery('2XLarge', 'min-width')} {
                  width: 180px;
                }
              `}
            />
          </Dashboard.Sidebar>

          <Dashboard.Main
            backgroundIcon={backgroundIcon}
            overlapSidebar
            css={css`
              ${dashboardMainCss}
              &.pq-main {
                overflow: visible !important;
              }
            `}
          >
            <div
              css={css`
                max-width: 1200px;
                width: ${pageWidth};
              `}
            >
              {children}
            </div>
          </Dashboard.Main>
        </Dashboard>
      </AuthLayoutWrapper>
    </>
  )
}

export { AuthLayout }
